<template>
  <div class="col-lg-10">
    <h4>{{ policyClass }} registry keys for {{ setId }}</h4>
    <div v-if="isLoading">Loading ...</div>
    <div v-else>
      <p>
        There are {{ model.uniquePolicies | formatNumber }} policies and
        {{ model.registries.length | formatNumber }} registry values.
      </p>
      <div>
        <table>
          <thead>
            <tr>
              <th>Policy</th>
              <th>Registry key</th>
              <th>Registry value</th>
              <th>Kind</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in model.registries" :key="item.hash">
              <td>{{ item.policyDisplayName }}</td>
              <td>{{ item.key }}</td>
              <td :class="{ 'special-value': item.valueName === '{Suffix}' }">
                {{ item.valueName }}
              </td>
              <td>{{ item.valueTypes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.special-value {
  color: gray;
  font-style: italic;
}
</style>

<script>
import * as Utility from "../utilities.js";
import XXH from "xxhashjs";

export default {
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
  },
  computed: {
    setId() {
      return this.$route.params.setId;
    },
    policyClass() {
      return this.$route.params.policyClass;
    },
    languageCode() {
      return this.$route.params.languageCode;
    },
  },
  methods: {
    fetchData() {
      const modelParams = {
        setId: this.setId,
        policyClass: this.policyClass,
        languageCode: this.languageCode,
      };

      // Reload the registry view, if it has changed
      if (!Utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;
        this.isLoading = true;

        this.$root.$api
          .getSetRegistry(
            modelParams.setId,
            modelParams.policyClass,
            modelParams.languageCode
          )
          .then((data) => {
            if (!Utility.shallowEqual(this.modelParams, modelParams)) return;

            for (const item of data.registries) {
              const hasher = XXH.h32(0x0000);
              hasher.update(item.policyId);
              hasher.update(item.key);
              hasher.update(item.valueName);
              hasher.update(item.valueTypes);

              const hashed = hasher.digest();
              const hashCode = hashed.toString(16);

              item.hash = hashCode;
            }

            this.model = data;
            this.isLoading = false;
          });
      }
    },
  },
  data() {
    return {
      isLoading: false,
      model: {
        uniquePolicies: 0,
        registries: [],
      },
      modelParams: {},
    };
  },
};
</script>
